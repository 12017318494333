<template>
  <div class="row m-0 justify-content-center holder">
    <div class="bg_holder">
      <div class="background" >
      </div>
    </div>
    <div class="col-12 p-0 above ">
      <slot />
    </div>
  </div>
</template>

<script>

export default {
  name: 'ContentWithBackgroundAndFooter'
}
</script>

<style scoped>

.holder {
  position: relative;
  z-index: 2;
}
.above {
  z-index: inherit;
}
.bg_holder {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  border-top-left-radius: 13px;
  border-top-right-radius: 13px;
  background-image: linear-gradient(rgba(255, 255, 255, 0.98) 0%, rgb(255, 255, 255) 100%);
  max-width: 100%;
  width: 100%;
  height: 100%;
  padding: 0;
}
.background {
  background-image: url(../../../../assets/images/bg.png);
  position: absolute;
  border-radius: 20px;
  background-size: 50%;
  opacity: 0.03;
  height: 100%;
  width: 100%;
  z-index: 1;
}
.rounded .bg_holder {
  border-radius: 13px;
}
.fullcard .rounded .bg_holder {
  border-radius: 0;
}
.fullcard .background {
  border-radius: 0;
}
.fullcard .bg_holder {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
@media (min-width: 992px) {
  .fullcard .rounded .bg_holder {
    border-radius: 13px;
  }
  .fullcard .background {
    border-radius: 20px;
  }
  .fullcard .bg_holder {
    border-top-left-radius: 13px;
  border-top-right-radius: 13px;
  }
}

</style>
